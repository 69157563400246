.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;  
  font-style: normal;
  font-weight: normal;
  line-height: normal; 
  font-family: Century; 
  overflow-x: hidden;
}

a {
  text-decoration: none;
}
